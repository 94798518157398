<template>
  <b-container fluid>
    <b-modal id="addFilesInFolder" size="lg" title="Add Files" hide-footer no-close-on-backdrop>
      <addFiles @addFolder="getAllFolders() ; $bvModal.hide('addFilesInFolder')"></addFiles>
    </b-modal>
    <b-modal id="addFolder" size="lg" title="Add Folder" hide-footer no-close-on-backdrop>
      <add-folder :parent="$route.params.id" @addFolder="getAllFolders() ; $bvModal.hide('addFolder')"></add-folder>
    </b-modal>
    <div class="iq-card p-3">
      <div class="d-flex justify-content-between align-items-center">
        <h5>File Manager</h5>
        <div class="d-flex gap-1">
          <b-button variant="primary" v-b-modal.addFolder>
            <i class="las la-folder-open"></i> Add Folder
          </b-button>
          <b-button variant="outline-primary" v-b-modal.addFilesInFolder>
            <i class="las la-file"></i> Add File
          </b-button>
        </div>
      </div>
    </div>
    <div class="iq-card p-3">
<!--      <b-row>-->
<!--        <draggable-->
<!--            class="w-100 col-md-1"-->
<!--            :list="allFolders.child"-->
<!--            :group="{ name: 'people', pull: 'clone', put: true }"-->
<!--            :move="checkMove"-->
<!--            @change="log"-->
<!--        >-->
<!--          <div-->
<!--              v-for="(folder, key) in allFolders.child"-->
<!--              :key="key"-->
<!--              @click="$router.push({params: {id: folder.id}})"-->
<!--          >-->
<!--            <img :src="require('@/assets/images/treeview/t-open-folder.png')" :alt="folder.title" class="w-100"/>-->
<!--            <p class="text-center text-dark">{{folder.title}}</p>-->x
<!--          </div>-->
<!--        </draggable>-->
<!--      </b-row>-->
      <b-row>
        <b-col md="1" sm="2" v-for="(folder, key) in allFolders.child" :key="key" class="cursor-pointer" @click="makeSound">
          <div @click="$router.push({params: {id: folder.id}})">
            <img :src="require('@/assets/images/treeview/t-open-folder.png')" :alt="folder.title" class="w-100"/>
            <p class="text-center text-dark">{{folder.title}}</p>
          </div>
        </b-col>
        <b-col md="1" sm="2" v-for="(folder, key1) in allFolders.file" :key="key1" class="cursor-pointer">
          <div class="p-2">
            <div class="main-box-file d-flex justify-content-center align-items-center">
              <img :src="folder.media.path_info" :alt="folder.title" class="w-100"/>
            </div>
            <p class="text-center text-dark">{{folder.title}}</p>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import fileServices from '../services/files'
import addFiles from '../components/addFile'
import addFolder from '../components/addFolder'
// import draggable from 'vuedraggable'
export default {
  name: 'allFiles',
  mounted () {
    core.index()
  },
  watch: {
    '$route.params' () {
      this.getAllFolders()
    }
  },
  components: { addFiles, addFolder },
  data () {
    return {
      dragging: '',
      sound: require('@/assets/images/treeview/Mouse-Click-00-c-FesliyanStudios.com.mp3'),
      animated: { enter: 'fadeInUp', exit: 'fadeOut' },
      allFolders: [],
      allFiles: []
    }
  },
  computed: {
    draggingInfo () {
      return this.dragging ? 'under drag' : ''
    }
  },
  methods: {
    log (e) {
      fileServices.move({
        folders: [this.allFolders.child[e.moved.oldIndex].id],
        destination: this.allFolders.child[e.moved.newIndex].id
      }).then(res => {
        this.getAllFolders()
        core.showSnackbar('success', res.data.message)
      })
    },
    checkMove: function (e) {
      this.dragging = false
    },
    getAllFolders () {
      fileServices.getAllFilesInFolders(this.$route.params.id).then(res => {
        this.allFolders = res.data.data
      })
    },
    makeSound () {
      const audio = new Audio(this.sound)
      audio.play()
    }
  },
  created () {
    this.getAllFolders()
  }
}
</script>
<style>
.nav-link.router-link-exact-active.router-link-active{
  background: var(--iq-primary) !important;
  color: #fff !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
}
[dir=ltr][mode=light] .nav-tabs {
  background: #fff !important;
  border-radius: 10px !important;
}
.gap-1 {
  gap: 1rem;
}
.fileImage {
  width: 100%;
  height: 100px;
}
</style>
