<template>
  <div>
    <ValidationObserver v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(saveFile)">
        <b-row class="d-flex align-items-center">
          <b-col md="12"  @click="clickOnInput">
            <b-form-group
                class="d-flex box-border-image justify-content-center align-items-center position-relative">
              <section class="w-100  flex-grow-1 cursor-pointer">
                <input type="file" id="attachments" ref="uploadCampaignImage"
                       @change="previewAttachemnt"
                       class="position-absolute capture_image cursor-pointer" style="opacity: 0;"/>
                <p ref="attachments" class="m-0 text-primary cursor-pointer text-center w-100"><i
                    class="las la-cloud-upload-alt"></i>{{ media_url ?'Edit': 'Add' }} Attachemnt</p>
                <p class="d-block text-success mt-2 w-100 text-center" v-if="media_url"><i
                    class="las la-cloud-upload-alt"></i> File Uploaded Successfuly</p>
                <b-progress v-if="progressBar" :value="progressBar" max="100" variant="primary" show-progress
                            animated></b-progress>
              </section>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <input-form :label="'Title'" validate="required" v-model="folder.title" name="Title"/>
          </b-col>
          <b-col md="12" class="d-flex justify-content-end align-items-center">
            <div>
              <b-button variant="primary" class="container_button_blue slideNextArrow" type="submit" v-if="!loadingAction">
                Save
              </b-button>
              <b-button variant="primary" class="container_button_blue slideNextArrow"  v-else>
                <spinner-loading text="Saving" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import fileService from '../services/files'
import { core } from '@/config/pluginInit'
import { mapGetters } from 'vuex'
import { commonDataMixin } from '@/mixins/commonMixin'
export default {
  mixins: [commonDataMixin],
  name: 'add Folder',
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  data () {
    return {
      folder: {
        title: '',
        file: 1,
        folder: this.$route.params.id
      },
      media_url: '',
      progressBar: 0,
      loadingAction: false
    }
  },
  methods: {
    clickOnInput () {
      this.$refs.uploadCampaignImage.click()
    },
    previewAttachemnt: function (e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createAttachemnt(files[0])
      const fd = new FormData()
      fd.append('file', files[0], files[0].name)
      fd.append('folder', 'driver')
      fd.append('access', 'public')
      var config = {
        onUploadProgress: (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor((loaded * 100) / total)
          console.log(percent)
          this.progressBar = percent
        }
      }
      this.commonUploadFiles(fd, config).then(res => {
        this.media_url = res.data.data.path
        this.folder.file = res.data.data.media_id
      })
    },
    createAttachemnt (file) {
      var reader = new FileReader()
      reader.readAsDataURL(file)
    },
    saveFile () {
      this.loadingAction = true
      fileService.saveFile(this.folder).then(res => {
        core.showSnackbar('success', res.data.message)
        this.$emit('addFolder')
      }).finally(() => {
        this.loadingAction = false
      })
    }
  }
}
</script>
